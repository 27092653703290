import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Creating an event`}</h2>
    <pre><code parentName="pre" {...{}}>{`POST https://api.video.ibm.com/v2/channels/CHANNEL_ID/events.json
`}</code></pre>
    <p>{`The parameters for the POST request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event description`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date in YYYY-MM-DD format. e.g. 2020-07-31`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`time`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time in HH:MM format. e.g. 16:50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timezone`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See `}<a parentName="td" {...{
              "href": "#list-of-supported-time-zones"
            }}>{`supported timezones`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`repeat`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of “never”, “daily”, “weekly”. default “never”`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`repeat_days`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid names: `}<inlineCode parentName="td">{`"Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday"`}</inlineCode>{` `}<strong parentName="td">{`Required if repeat is “weekly”, days MUST be separated by commas`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`duration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event duration in minutes. Valid values: 15, 30, 60, 90, 120, 180, 240`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response of Creating an event:`}</h3>
    <pre><code parentName="pre" {...{}}>{`HTTP/1.1 201 Created
Location: https://api.video.ibm.com/v2/channels/CHANNEL_ID/events/4552.json
`}</code></pre>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP response code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource ownership mismatch`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`429`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests (`}<a parentName="td" {...{
              "href": "/api-basics-rate-limits"
            }}>{`Read more`}</a>{`)`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Listing Events`}</h2>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/v2/channels/CHANNEL_ID/events.json
`}</code></pre>
    <p>{`The query parameters for the Listing Events GET request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`page`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page number (value is 1 by default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pagesize`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page size (value is 20 by default)`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with the following response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "events": [
        {
            "id": 4552,
            "title": "All hands",
            "description": "Company all hands meeting",
            "date": "2020-07-31",
            "time": "14:47",
            "timezone": "Europe/Budapest",
            "start_ts": 1596199536,
            "duration": 180,
            "repeat": "never"
        },
        {
            "id": 4554,
            "title": "Town hall",
            "description": "Company town hall meeting",
            "date": "2020-07-31",
            "time": "14:50",
            "timezone": "Europe/Budapest",
            "start_ts": 1596199836,
            "duration": 60,
            "repeat": "weekly",
            "repeat_days": [
                "Monday",
                "Saturday"
            ]
        }
    ],
    "paging": {
        "previous": "https://api.video.ibm.com/v2/channels/CHANNEL_ID/events.json?
pagesize=PAGE_SIZE&page=PREVIOUS_PAGE",
        "next": "https://api.video.ibm.com/v2/channels/CHANNEL_ID/events.json?
pagesize=PAGE_SIZE&page=NEXT_PAGE",
        "page_count": 8,
        "item_count": 38
    }
}
`}</code></pre>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP response code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource ownership mismatch`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`429`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests (`}<a parentName="td" {...{
              "href": "/api-basics-rate-limits"
            }}>{`Read more`}</a>{`)`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Listing Specific Event`}</h2>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/v2/channels/CHANNEL_ID/events/EVENT_ID.json
`}</code></pre>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with the following response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "event": {
    "id": 769115,
    "title": "Test Event Title",
    "description": "Desc",
    "date": "2020-12-09",
    "time": "16:28",
    "timezone": "Indian/Cocos",
    "start_ts": 1607531280,
    "duration": 60,
    "repeat": "weekly",
    "repeat_days": ["Monday", "Tuesday"]
  }
}
`}</code></pre>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP response code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field validation failed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource ownership mismatch`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`429`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests (`}<a parentName="td" {...{
              "href": "/api-basics-rate-limits"
            }}>{`Read more`}</a>{`)`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Event deletion`}</h2>
    <pre><code parentName="pre" {...{}}>{`DELETE https://api.video.ibm.com/v2/channels/CHANNEL_ID/events/EVENT_ID.json
`}</code></pre>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned.`}</p>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP response code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource ownership mismatch`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`429`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests (`}<a parentName="td" {...{
              "href": "/api-basics-rate-limits"
            }}>{`Read more`}</a>{`)`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Updating an event`}</h2>
    <pre><code parentName="pre" {...{}}>{`PUT https://api.video.ibm.com/v2/channels/CHANNEL_ID/events/EVENT_ID.json
`}</code></pre>
    <p>{`The parameters for the POST request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event description`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date in YYYY-MM-DD format. e.g. 2020-07-31`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`time`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time in HH:MM format. e.g. 16:50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timezone`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See `}<a parentName="td" {...{
              "href": "#list-of-supported-time-zones"
            }}>{`supported timezones`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`repeat`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of “never”, “daily”, “weekly”. default “never”`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`repeat_days`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid names: `}<inlineCode parentName="td">{`"Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday"`}</inlineCode>{` `}<strong parentName="td">{`Required if repeat is “weekly”, days MUST be separated by commas`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`duration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event duration in minutes. Valid values: 15, 30, 60, 90, 120, 180, 240`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned.`}</p>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP response code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field validation failed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource ownership mismatch`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`429`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests (`}<a parentName="td" {...{
              "href": "/api-basics-rate-limits"
            }}>{`Read more`}</a>{`)`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Get list of upcoming events on a channel`}</h2>
    <p>{`Returns data of upcoming events on a channel.`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/channels/{channel_id}/upcoming-events.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`The query parameters for the GET request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`page`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page number (value is 1 by default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pagesize`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page size (value is 50 by default, max. 50)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with the following key-value pairs under an `}<inlineCode parentName="p">{`events`}</inlineCode>{` key.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event description`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`start`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time when the event starts (Unix timestamp)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`duration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Expected duration of the event in seconds`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example of a success response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "events": [
        {
            "id": "111",
            "title": "Title of the event",
            "description": "Description of the event",
            "start": 1399388736,
            "duration": 900,
        },
        ...
    ]
}
`}</code></pre>
    <p>{`The paging information can be found under the `}<inlineCode parentName="p">{`paging`}</inlineCode>{` key. Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "paging": {
    "previous": "https://api.video.ibm.com/CHANNEL_ID/upcoming-events.json?pagesize=PAGE_SIZE&page=PREVIOUS_PAGE",
    "next": "https://api.video.ibm.com/channels/CHANNEL_ID/upcoming-events.json?pagesize=PAGE_SIZE&page=NEXT_PAGE"
  }
}
`}</code></pre>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`List of supported time zones`}</h2>
    <pre><code parentName="pre" {...{}}>{`Africa/Abidjan
Africa/Accra
Africa/Addis_Ababa
Africa/Algiers
Africa/Asmara
Africa/Asmera
Africa/Bamako
Africa/Bangui
Africa/Banjul
Africa/Bissau
Africa/Blantyre
Africa/Brazzaville
Africa/Bujumbura
Africa/Cairo
Africa/Casablanca
Africa/Ceuta
Africa/Conakry
Africa/Dakar
Africa/Dar_es_Salaam
Africa/Djibouti
Africa/Douala
Africa/El_Aaiun
Africa/Freetown
Africa/Gaborone
Africa/Harare
Africa/Johannesburg
Africa/Kampala
Africa/Khartoum
Africa/Kigali
Africa/Kinshasa
Africa/Lagos
Africa/Libreville
Africa/Lome
Africa/Luanda
Africa/Lubumbashi
Africa/Lusaka
Africa/Malabo
Africa/Maputo
Africa/Maseru
Africa/Mbabane
Africa/Mogadishu
Africa/Monrovia
Africa/Nairobi
Africa/Ndjamena
Africa/Niamey
Africa/Nouakchott
Africa/Ouagadougou
Africa/Porto-Novo
Africa/Sao_Tome
Africa/Timbuktu
Africa/Tripoli
Africa/Tunis
Africa/Windhoek
America/Adak
America/Anchorage
America/Anguilla
America/Antigua
America/Araguaina
America/Argentina/Buenos_Aires
America/Argentina/Catamarca
America/Argentina/ComodRivadavia
America/Argentina/Cordoba
America/Argentina/Jujuy
America/Argentina/La_Rioja
America/Argentina/Mendoza
America/Argentina/Rio_Gallegos
America/Argentina/Salta
America/Argentina/San_Juan
America/Argentina/San_Luis
America/Argentina/Tucuman
America/Argentina/Ushuaia
America/Aruba
America/Asuncion
America/Atikokan
America/Atka
America/Bahia
America/Barbados
America/Belem
America/Belize
America/Blanc-Sablon
America/Boa_Vista
America/Bogota
America/Boise
America/Buenos_Aires
America/Cambridge_Bay
America/Campo_Grande
America/Cancun
America/Caracas
America/Catamarca
America/Cayenne
America/Cayman
America/Chicago
America/Chihuahua
America/Coral_Harbour
America/Cordoba
America/Costa_Rica
America/Cuiaba
America/Curacao
America/Danmarkshavn
America/Dawson
America/Dawson_Creek
America/Denver
America/Detroit
America/Dominica
America/Edmonton
America/Eirunepe
America/El_Salvador
America/Ensenada
America/Fortaleza
America/Fort_Wayne
America/Glace_Bay
America/Godthab
America/Goose_Bay
America/Grand_Turk
America/Grenada
America/Guadeloupe
America/Guatemala
America/Guayaquil
America/Guyana
America/Halifax
America/Havana
America/Hermosillo
America/Indiana/Indianapolis
America/Indiana/Knox
America/Indiana/Marengo
America/Indiana/Petersburg
America/Indiana/Tell_City
America/Indiana/Vevay
America/Indiana/Vincennes
America/Indiana/Winamac
America/Indianapolis
America/Inuvik
America/Iqaluit
America/Jamaica
America/Jujuy
America/Juneau
America/Kentucky/Louisville
America/Kentucky/Monticello
America/Knox_IN
America/La_Paz
America/Lima
America/Los_Angeles
America/Louisville
America/Maceio
America/Managua
America/Manaus
America/Marigot
America/Martinique
America/Mazatlan
America/Mendoza
America/Menominee
America/Merida
America/Mexico_City
America/Miquelon
America/Moncton
America/Monterrey
America/Montevideo
America/Montreal
America/Montserrat
America/Nassau
America/New_York
America/Nipigon
America/Nome
America/Noronha
America/North_Dakota/Center
America/North_Dakota/New_Salem
America/Panama
America/Pangnirtung
America/Paramaribo
America/Phoenix
America/Port-au-Prince
America/Porto_Acre
America/Porto_Velho
America/Port_of_Spain
America/Puerto_Rico
America/Rainy_River
America/Rankin_Inlet
America/Recife
America/Regina
America/Resolute
America/Rio_Branco
America/Rosario
America/Santarem
America/Santiago
America/Santo_Domingo
America/Sao_Paulo
America/Scoresbysund
America/Shiprock
America/St_Barthelemy
America/St_Johns
America/St_Kitts
America/St_Lucia
America/St_Thomas
America/St_Vincent
America/Swift_Current
America/Tegucigalpa
America/Thule
America/Thunder_Bay
America/Tijuana
America/Toronto
America/Tortola
America/Vancouver
America/Virgin
America/Whitehorse
America/Winnipeg
America/Yakutat
America/Yellowknife
Antarctica/Casey
Antarctica/Davis
Antarctica/DumontDUrville
Antarctica/Mawson
Antarctica/McMurdo
Antarctica/Palmer
Antarctica/Rothera
Antarctica/South_Pole
Antarctica/Syowa
Antarctica/Vostok
Arctic/Longyearbyen
Asia/Aden
Asia/Almaty
Asia/Amman
Asia/Anadyr
Asia/Aqtau
Asia/Aqtobe
Asia/Ashgabat
Asia/Ashkhabad
Asia/Baghdad
Asia/Bahrain
Asia/Baku
Asia/Bangkok
Asia/Beirut
Asia/Bishkek
Asia/Brunei
Asia/Calcutta
Asia/Choibalsan
Asia/Chongqing
Asia/Chungking
Asia/Colombo
Asia/Dacca
Asia/Damascus
Asia/Dhaka
Asia/Dili
Asia/Dubai
Asia/Dushanbe
Asia/Gaza
Asia/Harbin
Asia/Hong_Kong
Asia/Hovd
Asia/Ho_Chi_Minh
Asia/Irkutsk
Asia/Istanbul
Asia/Jakarta
Asia/Jayapura
Asia/Jerusalem
Asia/Kabul
Asia/Kamchatka
Asia/Karachi
Asia/Kashgar
Asia/Kathmandu
Asia/Katmandu
Asia/Kolkata
Asia/Krasnoyarsk
Asia/Kuala_Lumpur
Asia/Kuching
Asia/Kuwait
Asia/Macao
Asia/Macau
Asia/Magadan
Asia/Makassar
Asia/Manila
Asia/Muscat
Asia/Nicosia
Asia/Novokuznetsk
Asia/Novosibirsk
Asia/Omsk
Asia/Oral
Asia/Phnom_Penh
Asia/Pontianak
Asia/Pyongyang
Asia/Qatar
Asia/Qyzylorda
Asia/Rangoon
Asia/Riyadh
Asia/Saigon
Asia/Sakhalin
Asia/Samarkand
Asia/Seoul
Asia/Shanghai
Asia/Singapore
Asia/Taipei
Asia/Tashkent
Asia/Tbilisi
Asia/Tehran
Asia/Tel_Aviv
Asia/Thimbu
Asia/Thimphu
Asia/Tokyo
Asia/Ujung_Pandang
Asia/Ulaanbaatar
Asia/Ulan_Bator
Asia/Urumqi
Asia/Vientiane
Asia/Vladivostok
Asia/Yakutsk
Asia/Yekaterinburg
Asia/Yerevan
Australia/ACT
Australia/Adelaide
Australia/Brisbane
Australia/Broken_Hill
Australia/Canberra
Australia/Currie
Australia/Darwin
Australia/Eucla
Australia/Hobart
Australia/LHI
Australia/Lindeman
Australia/Lord_Howe
Australia/Melbourne
Australia/North
Australia/NSW
Australia/Perth
Australia/Queensland
Australia/South
Australia/Sydney
Australia/Tasmania
Australia/Victoria
Australia/West
Australia/Yancowinna
Europe/Amsterdam
Europe/Andorra
Europe/Athens
Europe/Belfast
Europe/Belgrade
Europe/Berlin
Europe/Bratislava
Europe/Brussels
Europe/Bucharest
Europe/Budapest
Europe/Chisinau
Europe/Copenhagen
Europe/Dublin
Europe/Gibraltar
Europe/Guernsey
Europe/Helsinki
Europe/Isle_of_Man
Europe/Istanbul
Europe/Jersey
Europe/Kaliningrad
Europe/Kiev
Europe/Lisbon
Europe/Ljubljana
Europe/London
Europe/Luxembourg
Europe/Madrid
Europe/Malta
Europe/Mariehamn
Europe/Minsk
Europe/Monaco
Europe/Moscow
Europe/Nicosia
Europe/Oslo
Europe/Paris
Europe/Podgorica
Europe/Prague
Europe/Riga
Europe/Rome
Europe/Samara
Europe/San_Marino
Europe/Sarajevo
Europe/Simferopol
Europe/Skopje
Europe/Sofia
Europe/Stockholm
Europe/Tallinn
Europe/Tirane
Europe/Tiraspol
Europe/Uzhgorod
Europe/Vaduz
Europe/Vatican
Europe/Vienna
Europe/Vilnius
Europe/Volgograd
Europe/Warsaw
Europe/Zagreb
Europe/Zaporozhye
Europe/Zurich
Indian/Antananarivo
Indian/Chagos
Indian/Christmas
Indian/Cocos
Indian/Comoro
Indian/Kerguelen
Indian/Mahe
Indian/Maldives
Indian/Mauritius
Indian/Mayotte
Indian/Reunion
Pacific/Apia
Pacific/Auckland
Pacific/Chatham
Pacific/Easter
Pacific/Efate
Pacific/Enderbury
Pacific/Fakaofo
Pacific/Fiji
Pacific/Funafuti
Pacific/Galapagos
Pacific/Gambier
Pacific/Guadalcanal
Pacific/Guam
Pacific/Honolulu
Pacific/Johnston
Pacific/Kiritimati
Pacific/Kosrae
Pacific/Kwajalein
Pacific/Majuro
Pacific/Marquesas
Pacific/Midway
Pacific/Nauru
Pacific/Niue
Pacific/Norfolk
Pacific/Noumea
Pacific/Pago_Pago
Pacific/Palau
Pacific/Pitcairn
Pacific/Ponape
Pacific/Port_Moresby
Pacific/Rarotonga
Pacific/Saipan
Pacific/Samoa
Pacific/Tahiti
Pacific/Tarawa
Pacific/Tongatapu
Pacific/Truk
Pacific/Wake
Pacific/Wallis
Pacific/Yap
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      